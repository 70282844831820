import React from "react";
import "./resources.css";


// change the url in the href
// change the url that is white

const Resources = () => {
  return (
    <div className="mainContainer">
      <div className="resourcesContainer w-100 ">
        <h2 className="faqTitle ">Resources</h2>
      </div>
      <div className="mainText mt-3 row p-5 font-weight-bold text-left mb-2">
        <div className="mb-2">
          <h1>Please click on the link below:</h1>
        </div>
        <div className="">
          {" "}
          <a
            href="https://www.signupgenius.com/go/9040A4FABA82EAAFB6-54317621-scusd"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.signupgenius.com/go/9040A4FABA82EAAFB6-54317621-scusd
          </a>
        </div>
      </div>
    </div>
  );
};
export default Resources;